.footer_container {
    padding: 2rem 3rem;
    /* border: 1px solid var(--goldTxt); */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.footer_container_parent {
    /* border: 1px solid blue; */
    /* height: 10rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.footer-address {
    font-size: .9rem;
    color: var(--goldTxt);
    font-weight: bold;
}

.social-icons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    gap: 1rem;
}

.social-icons-container>img {
    width: 2rem;
    opacity: .7;
    height: 2rem;
}

.ownerName-footer{
    color: var(--primaryTxt);
    font-size: .9rem;
    font-weight: 500;
}

.footer-desc{
    color: var(--primaryTxt);
}

.logo-container-social{
    display: flex;
    /* border: 1px solid red; */
    align-items: center;
    width: 100%;
    justify-content: space-between;
}