* {
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  box-sizing: border-box;

  /* font-family: 'Kanit', sans-serif;
  font-family: 'Poppins', sans-serif; */

  font-family: 'Merriweather', serif;
  /* outline: 1px solid red; */
}

body {
  font-size: .8rem;
  /* line-height: 1rem; */
  font-weight: 400;
  /* overflow: hidden; */
}

:root {
  --maxWidth: 1300px;
  --whiteTxt: #dbdbdb;
  --primaryTxt: #3d3d3d;
  --goldTxt: #cf9455;
  --borderColor: #cf945542;
  /* --gradient-one: linear-gradient(134deg, #DFE9FF 0%, #FEE6E7 100%); */
}

input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.maxWidth {
  /* background-color: red; */
  max-width: var(--maxWidth);
  margin: auto;
}

/* SCROLLBAR */

::-webkit-scrollbar {
  width: .4em;
  height: .4em;
}

::-webkit-scrollbar-track {
  position: relative;
  right: 5px;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: var(--goldTxt);
  border-radius: 20px;
  outline: none;
}

.seprator {
  width: 100%;
  border: 1px solid #cf94559a;
  margin: 1rem;
}

.copyright-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* border: 1px solid red; */
}

.copyright-container>p {
  color: var(--primaryTxt);
  font-weight: bold;
}