.nav_container {
    /* border: 1px solid red; */
    padding: 1rem 3rem;
    position: fixed;
    z-index: 9;
    right: 0;
    left: 0;
    background-color: #00000021;
    transition: background-color 0.3s ease;
}

.nav_container.scrolled {
    background-color: #ffffff;
    /* Change to the desired background color when scrolled */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Optional: Add shadow when scrolled */
    transition: background-color 0.3s ease;
    /* Optional: Add smooth transition */
}

.nav_container_parent {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav_menu_container {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.logo-container>img {
    height: 2.5rem;
}

.companyName {
    color: var(--primaryTxt);
    font-size: 1.2rem;
    font-weight: 600;
}

.logo-container {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    gap: 10px;
}

.nav_menu_container>p {
    color: var(--primaryTxt);
    font-size: 1rem;
    font-weight: 600;
    transition: .1s ease-in;
    cursor: pointer;
}

.nav_menu_container>p:hover{
    color: var(--goldTxt);
}

.ham-icon {
    display: none;
}

.mob-nav-container {
    position: fixed;
    top: 72px;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
}

.nav_menu_container-mob{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;
    padding: 1rem;
    align-items: flex-end;
}

.nav_menu_container-mob>p{
    font-size: 1rem;
    color: var(--primaryTxt);
}

@media only screen and (max-width: 610px) {

    .ham-icon {
        display: block;
    }

    .poster-container-emergency {
        flex-direction: column;
    }

    .emergency-contact-container {
        height: 32rem !important;
        flex-direction: column;
    }

    .slide-container>h1 {
        padding: 0rem 1rem !important;
    }

    .slide-container>img {
        object-fit: cover !important;
    }

    .txt-container-emg {
        left: 1rem !important;
    }

    .emergency-contact-landingPg {
        height: 32rem !important;
    }

    .contactUs-container-parent {
        flex-direction: column;
    }

    .social-icons-container {
        display: none !important;
    }

    .nav_menu_container {
        display: none;
    }

    .aboutus-container {
        padding: 2rem 1rem !important;
    }

    .abtus-content {
        flex-direction: column;
    }

    .nav_container {
        padding: 1rem 1rem;
    }

    .slide-container-practices-child-container {
        flex-direction: column;
    }

    .slide-container-practices {
        height: auto !important;
    }

    .slide-container-practices-child {
        width: 100% !important;
        height: 20rem !important;
    }

    .slide-container-practices {
        padding: 2rem 1rem !important;
        padding-bottom: 4rem !important;
    }

    .practices-slider-header {
        padding: 2rem 1rem !important;
    }

    .contactUs-container {
        padding: 2rem 1rem !important;
    }

    .footer_container {
        padding: 2rem 1rem !important;
    }

    .form-container {
        width: 100% !important;
    }

    .form-container-parent {
        padding: 0rem !important;
    }

    .copyright-container {
        flex-direction: column !important;
        gap: 1rem;
    }

    .abtus-content {
        gap: 2rem;
    }

    .abtus-content-reverse {
        flex-direction: column-reverse !important;
    }

    .Title-animated {
        height: 10rem;
        word-break: break-word;
    }

    .disclaimer-container {
        width: 100% !important;
        padding: 0rem !important;
    }

}

@media only screen and (min-width: 361px) and (max-width: 610px) {
    .emergency-contact-container {
        height: 36rem !important;
    }

    .emergency-contact-landingPg {
        height: 36rem !important;
    }
}