.emergency-contact-landingPg {
    position: relative;
    height: 20rem;
    background-color: #fff;
}

.emergency-contact-container {
    height: 20rem;
    /* z-index: 1; */
    position: relative;
    display: flex;
}

.emergency-contact-container>* {
    flex: 1 1;
}

.emergency-contact-container-one {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../Assets/Images/emergency.jpg");
}

.emergency-contact-container-two {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../Assets/Images/appontment.jpg");
    background-size: cover;
}

.poster-container-emergency {
    display: flex;
    /* gap: 1rem; */
    position: absolute;
    right: 0;
    left: 0;
}

.poster-container-emergency>* {
    flex: 1 1;
    height: 20rem;
    /* border: 1px solid red; */
    /* background-color: YELLOW; */
}

.poster>img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: .3;
}

.emg-container:hover {
    cursor: pointer;
}

.emg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.txt-container-emg {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    height: 11rem;
    gap: 1rem;
    width: 23rem;
    /* border: 1px solid red; */
}

.txt-container-emg>p {
    color: var(--whiteTxt);
}

.head-emergency-text {
    font-size: 1.5rem;
    font-weight: 500;
}

.desc-emergency-text {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.txt {
    font-size: 1.2rem;
}

.txt-bold {
    font-size: 1.1rem;
}

.aboutus-container {
    padding: 2rem 3rem;
}

.Title-animated {
    color: var(--goldTxt);
    font-size: 2.3rem;
    text-align: center;
    margin-bottom: 3rem;
}

.abtus-content {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
}

.abtus-content>* {
    flex: 1 1;
    /* height: 10rem; */
    /* border: 1px solid red; */
}

.abtus-content-summary-title {
    font-size: 2.5rem;
    color: var(--goldTxt);
    font-weight: 500;
}

.abtus-content-summary {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.abtus-content-txt {
    font-size: 1rem;
    color: var(--primaryTxt);
}

.abtus-content-txt>span {
    color: var(--goldTxt);
}

.abtus-content-img {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.abtus-content-img-end {
    justify-content: flex-end;
}

.abtus-content-img-div {
    /* border: 1px solid red; */
    height: 100%;
    /* width: 19rem; */
    width: 32rem;

}

.abtus-content-img-div-landing {
    width: 32rem;
}

.abtus-content-img-div>img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.ourPractice-header {
    height: 17rem;
    background-color: black;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.ourPractice-header>img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    opacity: .5;
}

.ourPractice-header>h1 {
    position: absolute;
    /* top: 0; */
    left: 0;
    /* bottom: 0; */
    right: 0;
    z-index: 1;
    margin: auto;
    text-align: center;
    color: var(--whiteTxt);
}

.practices-slider-header {
    /* border: 1px solid red; */
    padding: 2rem 3rem;
    padding-bottom: 0;
}

.slide-container-practices {
    height: 26rem;
    background-color: #fff;
    padding: 2rem;
}

.slide-container-practices-child-container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
}

.slide-container-practices-child {
    width: 25%;
    height: -moz-fit-content;
    height: 20rem;
    background-color: #fff;
    border: 1px solid var(--borderColor);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    gap: 1rem;
    align-items: center;
    /* box-shadow: rgba(248, 255, 149, 0.449) 0px 1px 0px, rgba(194, 216, 129, 0.329) 0px 0px 8px; */
}

.slide-container-practices-child>p {
    font-size: 1rem;
    color: var(--primaryTxt);
    font-weight: bold;
    /* border: 1px solid red; */
    width: 100%;
    text-align: center;
}

.slide-container-practices-child>img {
    width: 13rem;
}

.fssai-logo {
    width: 10rem !important;
    position: relative;
    top: 2rem;
}

.rera-logo {
    width: 9rem !important;
    position: relative;
    top: 1rem;
}

.widget-container{
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 11;
    /* border: 1px solid red; */
}

.whatsapp-widget{
    width: 15rem;
    /* height: 3rem; */
    padding: .5rem 1rem;
    background-color: #0ebe00;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    display: flex;
    border-radius: 2rem;
    gap: .5rem;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    font-size: .9rem;
    cursor: pointer;
    transform: scale(.8);
    /* border: 1px solid blue; */
}

.whatsapp-widget>img{
    width: 2rem;
}

/* abtus-content-reverse */

@media only screen and (max-width: 610px){

    .widget-container{
        right: -0.5rem;
    }

    .abtus-content-reverse{
        flex-direction: column-reverse;
    }

}
