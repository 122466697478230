.btn {
    transition: all 0.1s ease;
    /* font-family: "Dosis", sans-serif; */
}

.btn {
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    background-color: #fff;
    color: var(--goldTxt);
    border: 1px solid var(--goldTxt);
    width: fit-content;
    min-width: 10rem;
    max-width: 20rem;
    font-weight: bold;
    padding: 10px 20px;
    height: 50px;
    box-shadow: none;
}

.btn:hover {
    /* transform: translateY(-3px); */
    background-color: #fff6ed;
}

.btn:active {
    opacity: 0.5;
}