.contactUs-container-parent {
    display: flex;
    /* border: 1px solid red; */
    /* align-items: center; */

}

.contactUs-container-parent>* {
    flex: 1 1;
    /* border: 1px solid blue; */
    /* height: 15rem; */
}

.contactUs-container {
    /* border: 1px solid green; */
    padding: 2rem 3rem;
    /* height: 20rem; */
    margin-bottom: 2rem;
}

.contactUs-container-hero-img {
    padding: 1rem 0rem;
}

.contactUs-container-hero-img>h1 {
    color: var(--goldTxt);
    text-align: center;
}

.form-container-parent {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.form-container {
    display: flex;
    flex-direction: column;
    /* border: 1px solid orange; */
    width: 80%;
    gap: 1.5rem;
}

.input-field {
    border: 1px solid var(--goldTxt);
    border-radius: 5px;
    height: 50px;
    padding: 0rem 1rem;
}

.input-field:focus {
    outline: none;
}

.input-field-textarea {
    height: 150px;
    max-width: 100%;
    padding: 1rem;
}

.input-field::placeholder {
    color: silver;
    font-size: .8rem;
}