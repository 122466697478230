 .ReactModal__Overlay--after-open{

    position: fixed;
    inset: 0px;
    z-index: 9 !important;
    background-color: rgb(15 15 15 / 75%) !important;

}

.disclaimer-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 45rem;
    padding: 1rem;
}

.disclaimer-container>h2{
    color: var(--goldTxt);
    font-size: 1.6rem;
}

.disclaimer-container>p{
    color: var(--primaryTxt);
    font-size: .9rem;
    font-weight: 500;
}

.disclaimer-container>p>span{
    color: var(--goldTxt);
    font-weight: bold;
}

.agreeBtn-container{
    display: flex;
    justify-content: flex-end;
}