.slide-container {
    height: 100dvh;
    /* width: 100vw; */
    background-color: black;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.slide-container>img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    opacity: .8;
    /* background-color: rgba(0, 0, 0, 0.219); */
}

.slide-container-five>img {
    opacity: .6;
}

.slide-container-four>img {
    opacity: .6;
}

.slide-container-two>img {
    opacity: .6;
}

.slide-container-six>img {
    opacity: .6;
}

.slide-container>h1 {
    position: absolute;
    padding: 0rem 3rem;
    font-size: 1.9rem;
    font-weight: 600;
    /* border: 1px solid red; */
    max-width: 1300px;
    width: 100%;
}

.slide-container-three>h1 {
    color: #ffffffc2;
}

.slide-container-one>h1 {
    color: #00000075;
}

.slide-container-two>h1 {
    top: 54%;
    color: #d6d6d69c;
}

.slide-container-four>h1 {
    top: 60%;
    color: #d6d6d6e8;
}

.slide-container-five>h1 {
    /* top: 33%; */
    color: #d6d6d6;
}